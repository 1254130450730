import request from '../../utils/request'
interface Item<T> {
  checkItemName?: T;
  checkItemType?: T;
  diseaseId?: T;
  size: number;
  current: number;
}
//获取预约数据
export function getOrderData(data: Item<string>): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/checkAppoint/list',
    type: 'params',
    data
  })
}
/** 根据点击预约订单获取订单列表 */
export function getOrderDetail(id: number) {
  return request({
    method: 'GET',
    url: 'manage/checkAppoint/' + id
  })
}


//获取预约数据
export function GgetOrderData(data: Item<string>): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/checkGroupAppointment/list',
    type: 'params',
    data
  })
}
/** 根据点击预约订单获取订单列表 */
export function GgetOrderDetail(id: number) {
  return request({
    method: 'GET',
    url: 'manage/checkGroupAppointment/' + id
  })
}



export function DownLoadGroup(pramar: any) {

  return request({
    method: "GET",
    url: "manage/checkGroupAppointment/hasExcelData" + `?checkStartTime=${pramar.startTime}&checkEndTime=${pramar.endTime}&type=${pramar.type}&companyName=${pramar.companyName}`
  })

}

export function DownLoadorderQuery(pramar: any) {

  return request({
    method: "GET",
    url: "manage/checkGroupAppointment/hasExcelData" + `?checkStartTime=${pramar.startTime}&checkEndTime=${pramar.endTime}&type=${pramar.type}`
  })

}



